.project-wrapper p, .single-post-wrapper p {
    margin-bottom: 2rem;
}

p a, li a, .section-intro a {
    font-weight: bold;
}

/* Contract for accessibility */
a.link-on-bg {
    color: #fff;
    text-decoration: underline;
}

html {
    background-color:#567787;
}

p.list-intro {
    margin-bottom:1rem;
}

h3.section-title {
    padding-top:10px;
}

/* Contract for accessibility */
a.nav-link {
    color:#eee !important;
}
