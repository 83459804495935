:root {
    --invalid: #ff4444;
}

span.invalid {
    color: var(--invalid);
}

input.invalid {
    border-color: var(--invalid) !important;
}
